.custom-navbar {
    background-color: #333;
    padding: 1rem 1.5rem;
  }
  
  .navbar-brand {
    color: #fff !important;
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .custom-navbar .nav-link {
    color: #fff !important;
    font-size: 1.2rem !important; /* Ensure font-size is marked as important */
    margin-right: 1rem;
  }
  
  .nav-link:hover {
    color: #007bff !important;
  }
  
  
  .custom-toggler {
    border-color: rgb(255, 255, 255) !important;
  }
  
  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }
  
  @media (max-width: 768px) {
    .nav-link {
      font-size: 1rem;
      margin-right: 0.5rem;
    }
  
    .navbar-brand {
      font-size: 1.5rem;
    }
  }
  