.home-container {
    text-align: center;
  }
  
  .home-header {
    position: relative;
    height: 100vh;
    background: url('../../public/images/background.jpg') no-repeat center center/cover; /* Ensure the path is correct */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 1rem;
  }
  
  .home-header h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .home-header p {
    font-size: 1.5rem;
  }
  
  .services-section {
    padding: 4rem 0;
    background-color: #f8f9fa;
  }
  
  .service-card {
    margin-bottom: 2rem;
    border: none;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    flex-grow: 1;
  }
  
  .service-card ul {
    padding-left: 1rem;
    text-align: left;
  }
  
  .service-card ul li {
    list-style-type: disc;
  }

  .service-card .card-body {
    padding-bottom: 8rem; 
  }
  
  .price-bubble {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: white;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 0.9rem;
  }
  
  .price-bubble span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 1.2;
    white-space: pre-wrap;
    word-wrap: break-word;
  }